import { Alert, notification, Spin } from 'antd';
import Button from 'antd-button-color';
import React, { useEffect, useMemo, useState } from 'react';
import { usePhoneBridge } from '@/components/PhoneBridgeProvider';
import { UnmountClosed } from 'react-collapse';
import useInterval from 'use-interval';
import {
  AudioMutedOutlined,
  DownSquareFilled,
  PauseOutlined,
  PhoneOutlined,
  UpSquareFilled,
} from '@ant-design/icons';
import useCreateManualCall from '@/services/api/call/useCreateManualCall';
import useUpdateManualCall from '@/services/api/call/useUpdateManualCall';
import { addHours } from 'date-fns';
import useUploadFile from '@/services/api/file/useUploadFile';
import useMyAgent from '@/services/api/agent/useMyAgent';
import { FaPhoneSlash } from 'react-icons/fa';
import useCustomers from '@/services/api/customer/useCustomers';
import parsePhoneNumber from 'libphonenumber-js';
import DetailCustomerButton from '@/pages/customers/components/DetailCustomerButton';
import useCreateCustomer from '@/services/api/customer/useCreateCustomer';
import { last, meanBy } from 'lodash';
import { usePermission } from '@/services/api/permission/usePermissions';
import { useIntl } from 'umi';

const LineCall = ({ lineObj }) => {
  const view_contact_customer = usePermission('view_contact_customer');
  const [didStarted, setDidStarted] = useState(false);
  const { data: agentData } = useMyAgent();
  const [currentId, setCurrentId] = useState(undefined);
  const { mutateAsync: createCall } = useCreateManualCall();
  const { mutateAsync: updateCall } = useUpdateManualCall();
  const { mutateAsync: uploadFile } = useUploadFile();
  const [status, setStatus] = useState('');
  const [timer, setTimer] = useState('');
  const [realTimeLineObj, setRealTimeLineObj] = useState(undefined);
  const { activeLineNumber, setActiveLineNumber, getIframe } = usePhoneBridge();
  const isActive = lineObj?.LineNumber === activeLineNumber;
  const isMaskingPhoneNumber = !view_contact_customer || false;
  const callerID = isMaskingPhoneNumber
    ? lineObj?.SipSession?.remoteIdentity?.displayNumber?.toString()?.replace(/.(?=.{4,}$)/g, '*')
    : lineObj?.SipSession?.remoteIdentity?.displayNumber;
  const DisplayNumber = `${callerID || ''}<${lineObj?.SipSession?.remoteIdentity?.uri?.user}>`;
  const FullDisplayNumber = lineObj?.DisplayNumber;
  const { data: customersSearchData, refetch: refetchSearchCustomers } = useCustomers({
    filters: {
      search: parsePhoneNumber(FullDisplayNumber, 'VN')?.nationalNumber,
    },
  });
  const { mutateAsync: createCustomer } = useCreateCustomer();
  const customer = customersSearchData?.data?.results?.[0];
  const SipSession = lineObj?.SipSession;
  const BuddyObj = lineObj?.BuddyObj;
  const LineNumber = lineObj?.LineNumber;
  const started = SipSession?.data?.started;
  const calldirection = SipSession?.data?.calldirection;
  const isInbound = calldirection === 'inbound';
  const isOutbound = calldirection === 'outbound';
  const reasonText = SipSession?.data?.reasonText;
  
  const ismute = SipSession?.data?.ismute;
  const isOnHold = SipSession?.isOnHold;
  const LocalSoundMeter = realTimeLineObj?.LocalSoundMeter;
  const RemoteSoundMeter = realTimeLineObj?.RemoteSoundMeter;
  const LastSendBitRate = last(LocalSoundMeter?.SendBitRate || []);
  const LastReceiveBitRate = last(RemoteSoundMeter?.ReceiveBitRate || []);
  const averageSendBitRate = meanBy(RemoteSoundMeter?.ReceiveBitRate || [], 'value');
  const { formatMessage } = useIntl();
  // console.log('averageSendBitRate', averageSendBitRate);
  const displayTimeCount = useMemo(() => {
    return status === 'Call in Progress!';
  }, [status]);
  const maskedNumber = `<********${DisplayNumber?.toString()?.slice(-4)}`;

  const mappedStatus = useMemo(() => {
    if (status === 'Call Failed') {
      if (window.callEndedCallback) {
        window.callEndedCallback(LineNumber);
      }
      return formatMessage({ id: 'khhng_th_gi' });
    }
    if (status === 'Call ended, bye!') {
      if (window.callEndedCallback) {
        window.callEndedCallback(LineNumber);
      }
      return formatMessage({ id: 'cuc_gi_kt_thc' });
    }
    if (status === 'Call Cancelled') {
      return formatMessage({ id: '_gc_my' });
    }
    if (status === 'Ringing...' || status === 'Session Progress...') {
      return formatMessage({ id: 'ang__chung' });
    }
    if (status === 'Starting Audio Call...') {
      return formatMessage({ id: 'ang_chun_b' });
    }
    if (status === 'Trying...') {
      return formatMessage({ id: 'ang_kt_ni2' });
    }
    if (status === 'Call Rejected') {
      if (window.callEndedCallback) {
        window.callEndedCallback(LineNumber);
      }
      return formatMessage({ id: 'cuc_gi_b_t_chi' });
    }
    if (status === 'Call in Progress!') {
      return formatMessage({ id: 'ang_nghe2' });
    }
    if (status === 'Incoming Call') {
      return formatMessage({ id: 'cuc_gi_n' });
    }
    if (status === 'Call on Hold') {
      return formatMessage({ id: 'ang_gi_my' });
    }
    if (status === 'Call Recording Stopped') {
      return formatMessage({ id: '_ll_ghi_m_cuc_gi' });
    }
    return status;
  }, [status]);

  const callDirectionIcon = useMemo(() => {
    if (calldirection === 'outbound') {
      return <UpSquareFilled />;
    }
    return <DownSquareFilled />;
  }, [calldirection]);

  const holdSession = () => {
    getIframe()?.holdSession(LineNumber);
  };
  const unholdSession = () => {
    getIframe()?.unholdSession(LineNumber);
  };
  const MuteSession = () => {
    getIframe()?.MuteSession(LineNumber);
  };
  const UnmuteSession = () => {
    getIframe()?.UnmuteSession(LineNumber);
  };

  useEffect(() => {
    if(reasonText === "Busy Here" || reasonText === "Decline" || reasonText === 'Temporarily Unavailable' || reasonText === 'Service Unavailable') {
      if (window.callEndedCallback) {
        window.callEndedCallback(LineNumber);
      }
    }
  },[reasonText] )

  useEffect(() => {
    if (reasonText !== '' && reasonText && currentId) {
      let terminateby;
      let _reasonText;
      
      if (window.agentCampaignCallback && SipSession?.data?.terminateby === 'them') {
        if (
          reasonText === 'Decline' ||
          reasonText === 'Busy Here' ||
          reasonText === 'Temporarily Unavailable'
        ) {
          window.agentCampaignCallback();
        }
      }
      switch (SipSession?.data?.terminateby) {
        case 'them':
          terminateby = formatMessage({ id: 'khch' });
          break;
        case 'us':
          terminateby = formatMessage({ id: 'tng_i_vin' });
          break;
      }
      switch (reasonText) {
        case 'Request Timeout':
          _reasonText = formatMessage({ id: 'qu_thi_gian_ch' });
          break;
        case 'Decline':
          _reasonText = formatMessage({ id: 't_chi_nghe_my' });
          break;
        case 'Normal Call clearing':
          _reasonText = formatMessage({ id: 'cp_my' });
          break;
        case 'Call Cancelled':
          _reasonText = formatMessage({ id: 'cp_my' });
          break;
        case 'Client Error':
          _reasonText = formatMessage({ id: 'li_ng_truyn' });
          break;
        case 'Busy Here':   
          _reasonText = formatMessage({ id: 'my_bn' });
          break;
        case 'Request Terminated':
          _reasonText = formatMessage({ id: 'hu_cuc_gi' });
          break;
        case 'Temporarily Unavailable':
          _reasonText = formatMessage({ id: 'ngoi_vng_ph_sng' });
          break;
        default:
          _reasonText = reasonText;
      }

      // updateCall({
      //   id: currentId,
      //   reason_code: SipSession?.data?.reasonCode,
      //   end_at: addHours(new Date(), 7)?.toISOString(),
      // });

      if (SipSession?.data?.terminateby === 'us') {
        return;
      }

      notification.warn({
        message: `${formatMessage({ id: 'phone' })} ${DisplayNumber} ${formatMessage({
          id: '_chm_dt_bi_l_do',
        })} ${_reasonText}`,
        description: `${formatMessage({ id: 'ngi_gc_my' })}: ${terminateby}`,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [reasonText, currentId]);

  useInterval(() => {
    setStatus(
      getIframe()
        ?.$('#line-' + lineObj.LineNumber + '-msg')
        ?.text(),
    );
    setTimer(
      getIframe()
        ?.$('#line-' + LineNumber + '-timer')
        ?.text(),
    );
    setRealTimeLineObj(getIframe()?.FindLineByNumber(LineNumber));
  }, 1000);

  useEffect(() => {
    if (status) {
      if (status === 'Call in Progress!') {
        if (!didStarted) {
          // updateCall({
          //   id: currentId,
          //   start_at: addHours(new Date(), 7)?.toISOString(),
          //   status: 'ANSWERED',
          // });
        }
        setDidStarted(true);
      }
    }
  }, [status]);

  useEffect(() => {
    if (customersSearchData?.data?.results && customersSearchData?.data?.results?.length === 0) {
      createCustomer({
        phone_number: FullDisplayNumber,
      }).then(() => {
        refetchSearchCustomers();
      });
    }
  }, [customersSearchData]);
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (agentData?.data) {
      let _params = {
        status: 'PENDING',
        direction: calldirection?.toUpperCase(),
        numberInfo: {
          from_number: calldirection === 'inbound' ? FullDisplayNumber : null,
          to_number: calldirection === 'inbound' ? null : FullDisplayNumber,
        },
        client_session: SipSession?._id,
      };
      if (window.agentCampaignCallback) {
        _params = {
          ..._params,
          agent_call_campaign_customer: window.currentFocusedAgentCampaignCustomer,
        };
      }
      // createCall({
      //   ..._params,
      // }).then(({ data: call }) => {
      //   setCurrentId(call?.id);
      // });
    }
  }, [agentData]);

  return (
    <Alert
      banner
      style={{
        width: '100%',
        margin: '0 8px',
        border: '1.5px solid var(--ant-primary-color)',
        borderRadius: '4px',
        transition: '0.3s all',
      }}
      action={
        <div className="d-flex flex-row">
          <UnmountClosed isOpened={!started && isInbound}>
            <div className="d-flex flex-row" style={{ marginBottom: '12px' }}>
              <div className="col-6" style={{ paddingRight: '8px' }}>
                <Button
                  block
                  onClick={() => {
                    getIframe().AnswerAudioCall(LineNumber);
                  }}
                  type="success"
                  style={{ width: '100%' }}
                >
                  <PhoneOutlined />
                  {formatMessage({ id: 'tr_li' })}
                </Button>
              </div>

              <div className="col-6" style={{ paddingLeft: '8px' }}>
                <Button
                  block
                  onClick={() => {
                    getIframe().RejectCall(LineNumber);
                  }}
                  style={{ width: '100%' }}
                >
                  <FaPhoneSlash size={16} style={{ marginRight: '4px', marginTop: '4px' }} />
                  {formatMessage({ id: 't_chi' })}
                </Button>
              </div>
            </div>
          </UnmountClosed>
          <UnmountClosed isOpened={started}>
            <div className="d-flex flex-row" style={{ marginBottom: '12px' }}>
              <div className="col-6 pr-1">
                {!isOnHold && (
                  <Button onClick={holdSession} block style={{ width: '98%' }} type="lightdark">
                    <PauseOutlined />
                    {formatMessage({ id: 'gi_my' })}
                  </Button>
                )}
                {isOnHold && (
                  <Button onClick={unholdSession} block style={{ width: '98%' }} type="success">
                    <PauseOutlined />
                    {formatMessage({ id: 'tt_mic' })}
                  </Button>
                )}
              </div>
              <div className="col-6 pl-1">
                {!ismute && (
                  <Button onClick={MuteSession} block style={{ width: '98%' }} type="lightdark">
                    <AudioMutedOutlined />
                    {formatMessage({ id: 'tt_mic' })}
                  </Button>
                )}
                {ismute && (
                  <Button onClick={UnmuteSession} block style={{ width: '98%' }} type="success">
                    <AudioMutedOutlined />
                    {formatMessage({ id: 'bt_mic' })}
                  </Button>
                )}
              </div>
            </div>
          </UnmountClosed>

          {(!isInbound || (isInbound && started)) && (
            <Button
              block
              onClick={() => {
                getIframe()?.cancelSession(LineNumber);
                getIframe()?.endSession(LineNumber);
              }}
              type="danger"
              style={{ width: '100%', marginBottom: '12px' }}
            >
              <FaPhoneSlash size={16} style={{ marginRight: '4px', marginTop: '4px' }} />
              {formatMessage({ id: 'gc_my' })}
            </Button>
          )}
        </div>
      }
      icon={callDirectionIcon}
      onClick={() => {
        setActiveLineNumber(LineNumber);
      }}
      message={
        <div
          className="d-flex flex-row align-items-center justify-content-between"
          style={!isActive ? { opacity: 0.7 } : {}}
        >
          <b>
            {mappedStatus ? mappedStatus : <Spin />}
            {`${view_contact_customer ? DisplayNumber : maskedNumber}`}
            {/* {`: ${DisplayNumber}`} */}
          </b>
        </div>
      }
      description={
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <b>{displayTimeCount && timer}</b>

            {averageSendBitRate <= 8 && (
              <b style={{ color: 'var(--ant-error-color)', marginLeft: '12px' }}>
                {formatMessage({ id: 'cht_lng_mng_khng_n_nh' })} - Send: {LastSendBitRate?.value}
                kbps, Receive: {LastReceiveBitRate?.value}kbps
              </b>
            )}
          </div>
          {customer?.id && (
            <DetailCustomerButton
              customerID={customer?.id}
              buttonOverride={
                <Button style={{ marginTop: '12px', maxWidth: '180px' }}>
                  {formatMessage({ id: 'sa_thng_tin' })}
                </Button>
              }
              isMaskingNumber={isMaskingPhoneNumber}
            />
          )}
        </div>
      }
      type={'info'}
    />
  );
};

export default LineCall;
