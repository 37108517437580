import { StringLiteral } from 'typescript';

export interface DefaultServerError {
  status: string;
  message: string;
}

export interface ServerFormError {
  [x: string]: string[];
}

export interface IPaginate<T> {
  count: number;
  page_count: number;
  results: T[];
  total?: any;
}

export enum CookiesConstant {
  API_KEY = 'api_key',
  ACCESS_TOKEN = 'access',
  REFRESH_TOKEN = 'refresh',
  CSRFToken = 'csrftoken',
}

export interface ICompany {
  id: string;
  name: string;
  slug: string;
  partner: IPartner;
  company_name?: string;
  is_tax?: boolean;
  location?: string;
  tax?: string;
  tax_email?: string;
}

export interface IAffiliateList {
  id: string;
  name: string;
  slug: string;
  contact_email: string;
  active: boolean;
  pack?: string;
  amount?: string;
  created_at: string;
}

export interface IAffiliateSlug {
  id: string;
  total_amount: number;
  quantity_of_company_sub?: any;
  total_companies?: any;
  updated_at: string;
  created_at: string;
  slug: string;
  amount: number;
  user: string;
}

export interface IAffiliateTransaction {
  id: string;
  package_name: string;
  email: string;
  updated_at: any;
  created_at?: any;
  rollback_at?: any;
  status: string;
  type: string;
  affiliate_partner: string;
  amount: number;
  ordercart?: string;
}

export interface IProfile {
  id: string;
  email: string;
  phone_number: string;
  company: ICompany;
  types: string[];
}

export interface ICustomer {
  id: string;
  company: Company;
  manager: Manager;
  employees: Manager[];
  name: string;
  email: string;
  phone_number: string;
  full_phone_number: string;
  gender: string;
  call_status: string;
  other_attributes: any;
  updated_at: Date;
  created_at: Date;
}

export interface Company {
  id: string;
  name: string;
  slug: string;
}

export interface Manager {
  id: string;
  email: string;
  phone_number: string;
}

export interface IJob {
  id: string;
  profile: Profile;
  title: string;
  description: string;
  error_messages: string;
  amount: number;
  total: number;
  status: string;
  updated_at: Date;
  created_at: Date;
}

export interface Profile {
  id: string;
  email: string;
  phone_number: string;
}

export interface ICustomerGroup {
  id: string;
  name: string;
  updated_at: Date;
  created_at: Date;
  count_customers: number;
}

export interface ICampaign {
  id: string;
  name: string;
  status: string;
  manager?: IProfile;
  employees?: IProfile[];
  updated_at: string;
  created_at: string;
}

export interface IDeleteSms {

  SMS_ID: string
}

export interface IDeleteSmsTemplate {
  id: string;
}

export interface ICallCenter {
  id: string;
  name: any;
}

export interface ICallBotScenarioSample {
  id: string;
  index: number;
  link: string;
  title: string;
  description: string;
  content: any;
  is_verified: boolean;
  updated_at: Date;
  created_at: Date;
}

export interface ICallBotScenario {
  id: string;
  link: string;
  title: string;
  description: string;
  content: any;
  is_verified: boolean;
  updated_at: Date;
  created_at: Date;
}

export interface ICampaignSetting {
  id: string;
  price: string;
  customer_groups: ICustomerGroup[];
  call_bot_scenario: ICallBotScenario;
  call_bot_voice: IVoice;
  time_conditions: ITimeRangeCondition[];
  hotline_type: string;
  recall_when_busy: boolean;
  recall_when_voice_mail: boolean;
  recall_when_no_answer: boolean;
  recall_after_duration: number;
  recall_number: number;
  call_concurrency: number;
  updated_at: Date;
  created_at: Date;
  start_date: Date;
  type: string;
}

export interface IFilterCollumns {
  type?: any;
  status?: any;
  direction?: any;
  profile?: any;
  customer_group?: any;
}

export interface ITimeRangeCondition {
  id: string;
  company: Company;
  name: string;
  start_at: string;
  end_at: string;
  updated_at: Date;
  created_at: Date;
}

export interface ICreateSmsOfficialAccount {
  zalo_oa_code: string;
  type_app: string;
  zalo_oa_id: string;
  app_ucall_id: string;
  app_ucall_secret_key: string;
}

export interface ICreateSmsTemplate {
  type_template: string;
  status: string;
  name: string;
  content: string;
  params: any;
  official_account_id: string;
}

export interface ICreateSmsCampaignZalo {
  type_message: string;
  name: string;
  type_send: string;
}

export interface ITimeCondition {
  id: string;
  company: Company;
  name: string;
  time_ranges: ITimeRangeCondition[];
  updated_at: Date;
  created_at: Date;
}

export interface IApiKey {
  key: string;
  is_active: boolean;
  created_at: string;
}

export interface IVoice {
  id: number;
  title: string;
  audio_url: string;
  type: 'BASIC' | 'ADVANCE' | 'HUMAN';
}

export interface IWallet {
  id: string;
  amount: number;
  updated_at: Date;
  created_at: Date;
  expire_at: Date;
}

export interface IHotlineFree {
  id: string;
  updated_at: string;
  created_at: string;
  phone_number: string;
  carrier: string;
  type: string;
  source: string;
  status: string;
  other_attributes: {
    carriers: string[];
    call_server: string[];
  };
  sale_status: string;
  setup_ammount: number;
  period_ammount: number;
  period: string;
  renewal_type: string;
  onnet_use_amount: number;
  mobile_use_amount: number;
  company: string[];
  partner: any[];
}

export interface ILabel {
  id: string;
  text: string;
}

export interface ICallCampaignReport {
  id: string;
  completed_customer: number;
  total_customer: number;
  paid_money: number;
  total_call_duration: number;
  updated_at: Date;
  created_at: Date;
}

export interface IListVoiceMail {
  id: string;
  record: string;
  duration: string;
  start_at: number;
}

export interface ICallCampaignReportChart {
  id: string;
  name: string;
  type: 'PIE' | 'AREA' | 'BAR';
  data: any;
  updated_at: Date;
  created_at: Date;
}
export interface IDialReport {
  total_dial: number;
  fail: number;
  connected: number;
  success: number;
  deny: number;
  callback: number;
  wait_report: number;
  no_employee: number;
  duration: number;
  bill_duration: number;
  success_rate: any;
}

export interface ITransaction {
  id: string;
  amount: number;
  status: string;
  updated_at: Date;
  created_at: Date;
}

export interface ICallCampaignCustomer {
  id: string;
  customer: string | ICustomer;
  carrier: string;
  status: string;
  phone_number: string;
  custom_fields?: ICustomField[];
  other_attributes: any;
  call_count: number;
  retry_at: Date;
  updated_at: Date;
  created_at: Date;
}

interface ICustomField {
  key?: string;
  val?: any;
}

export interface ICallBotDetail {
  id: string;
  title: string;
  description: string;
  content: {
    icon: string;
    name: string;
    drawflow: string;
    settings: any;
    dataColumns: any;
  };
  link: string;
  is_verified: boolean;
  error_messages: string;
  updated_at: string;
  created_at: string;
}

export interface IIndustry {
  index: number;
  industry_key: string;
  industry_name: string;
}

export interface INotification {
  id: number;
  title: string;
  body: string;
  image?: string;
  is_read: boolean;
  created_at: Date;
  priority: number;
}

export interface IPaymentUrl {
  url: string;
}

export interface IPackage {
  index: number;
  package_id: number;
  package_name: string;
  amount: string;
  promotion: string;
  expiration: string;
  payment_method: string[];
}

export interface IPartner {
  name: string;
  is_default: boolean;
  logo?: string;
  dark_logo?: string;
  price_voice_record: number;
  price_voice_basic: number;
  price_voice_advance: number;
  price_hotline_hub: number;
  price_hotline_own: number;
  bank_name: string;
  bank_number: string;
  bank_user: string;
  bank_content: string;
  bank_qr: string;
}

export interface IAddress {
  id: number;
  code: string;
  type: string;
  name: string;
}

export interface IBusinessType {
  index: number;
  id: string;
  name: string;
}

export interface IFacebookGroup {
  id: string;
  fb_id: string;
  fb_url: string;
  have_phone_members: number;
  type: string;
  status: string;
  last_crawled_at: any;
  updated_at: string;
  created_at: string;
}

export interface IStatisticize {
  total: number;
}

export interface IAgent {
  id: string;
  ext_domain: string;
  ext_username: string;
  ext_password: string;
  ext_number: string;
  phone_number: string;
  status: string;
  updated_at: string;
  created_at: string;
  profile: string;
  company: string;
  is_hidden_phone: boolean;
}

export interface ICall {
  id: number;
  client_session: string;
  company: string;
  employee: string;
  customer: any;
  hotline: any;
  to_number: any;
  carrier: string;
  record: string;
  duration: number;
  start_at: string;
  end_at: any;
  status: string;
  hangup_cause: string;
  reason_code: number;
  direction: string;
  type: string;
  updated_at: string;
  created_at: string;
}

export interface ICallCampaignReport {
  count: number;
  page_count: number;
  results: ICallCampaignReportCustomer[];
}

export interface ICallCampaignReportCustomer {
  id: string;
  facebook_info: any[];
  other_attributes: {
    fuid: string;
    name: string;
    email: string;
    gender: string;
    source: string;
  };
  phone_number: string;
  full_phone_number: string;
  status: string;
  call_count: number;
  start_at: any;
  duration: number;
  call_status: string;
  quality: string;
  record: string;
  call_results: any;
  labels__id: {
    id: string;
    text: string;
  }[];
  note: string;
}

export interface IVietQrResponse {
  url: string;
  order_id: string;
}

export interface ICallAgentScenario {
  id: string;
  title: string;
  description: string;
  greeting: string;
  content: any;
  updated_at: string;
  created_at: string;
  company: string;
}

export interface IAgentCallCampaign {
  id: string;
  name: string;
  status: string;
  messages: string;
  log_messages: string;
  updated_at: string;
  created_at: string;
}

export interface IAgentCallCampaignSettings {
  id: string;
  manager: IManager;
  employees: any[];
  call_agent_scenario: any;
  time_conditions: any[];
  report_by_time_condition: boolean;
  hotlines: any[];
  same_carrier_priority: boolean;
  recall_when_busy: boolean;
  recall_when_voice_mail: boolean;
  recall_when_no_answer: boolean;
  recall_number: number;
  recall_after_duration: number;
  updated_at: string;
  created_at: string;
}

export interface IManager {
  id: string;
  username: any;
  email: string;
  phone_number: string;
}

export interface IHotline {
  id: string;
  phone_number: string;
  carrier: string;
  updated_at: string;
  created_at: string;
}

export interface IListCreateSmsOfficialAccount {
  count: number;
  page_count: number;
  results: [
    {
      id: string;
      oa_infor: {
        oa_id: string;
        name: string;
        description: string;
        is_verified: boolean;
        oa_type: number;
        cate_name: string;
        num_follower: number;
        avatar: string;
        cover: string;
        package_name: string;
        package_valid_through_date: string;
        package_auto_renew_date: string;
        linked_zca: string;
      };
      type_app: string;
      zalo_oa_id: string;
      zalo_oa_code: string;
      app_ucall_id: string;
      app_ucall_secret_key: string;
      access_token: string;
      refresh_token: string;
      expire_in: string;
      user_htc: any;
      pass_htc: any;
      ip_client: any;
      is_active: any;
      updated_at: string;
      created_at: string;
      company: string;
      manager: string;
    },
  ];
  total: any;
}

export interface IDetailSmsTemplate {
  id: string;
  type_template: string;
  status: string;
  name: string;
  content: string;
  key_template_zalo_id: any;
  params: any;
  previewUrl: any;
  templateQuality: any;
  templateTag: any;
  price: number;
  updated_at: string;
  created_at: string;
  official_account_id: string;
}

export interface IListSmsTemplate {
  count: number;
  page_count: number;
  results: [
    {
      id: string;
      type_template: string;
      status: string;
      name: string;
      content: string;
      key_template_zalo_id: any;
      params: any;
      previewUrl: any;
      templateQuality: any;
      templateTag: any;
      price: number;
      updated_at: string;
      created_at: string;
      official_account_id: string;
    },
  ];
  total: any;
}
export interface IListSmsCampaignZalo {
  count: number;
  page_count: number;
  results: [
    {
      id: string;
      type_message: string;
      name: string;
      sending_time: string;
      updated_at: string;
      created_at: string;
      company: string;
      manager: string;
      official_account_id: string;
      sms_template_id: string;
    },
  ];
  total: any;
}

export interface IListSmsCampaignCustomer {
  id: string;
  phone_number: string;
  content: string;
  other_attributes: {
    date: string;
    madonhang: number;
    product_name: string;
    customer_name: string;
  };
  status: string;
  sms_result: {
    data: {
      qouta: {
        dailyQouta: number;
        remainingQouta: number;
      };

      msg_id: string;
      sent_time: string;
      sending_mode: number;
    };

    error: number;
    message: string;
  };
  updated_at: string;
  created_at: string;
  sms_campaign_ott: string;
  sms_campaign_sms: string;
  customer: string;
  employee: string;
}

export interface IListSmsCampaignReport {
  id: string;
  total_sms_success: number;
  toatl_sms_fail: number;
  total_fee: number;
  updated_at: string;
  created_at: string;
  sms_campaign_ott: string;
  sms_campaign_sms: string;
}

export interface ICustomerRunner {
  id: string;
  call_id: string;
  agent: IAgent;
  other_attributes: any;
  phone_number: string;
  full_phone_number: string;
  note: string;
  labels: any[];
  updated_at: string;
  created_at: string;
}

export interface IPermission {
  id: number;
  name: string;
  content_type: {
    id: number;
    model: string;
  };
  codename: string;
}

export interface VIETTEL {
  ext_domain: string;
  ext_username: string;
  ext_password: string;
  ext_number: string;
}

export enum NETWORK_PROVIDER {
  VIETTEL = 'VIETTEL',
  MOBIFONE = 'MOBIFONE',
  VINAPHONE = 'VINAPHONE',
  OTHER = 'OTHER',
}

export interface VINAPHONE {
  ext_domain: string;
  ext_username: string;
  ext_password: string;
  ext_number: string;
}

export interface MOBIFONE {
  ext_domain: string;
  ext_username: string;
  ext_password: string;
  ext_number: string;
}

export interface OTHER {
  ext_domain: string;
  ext_username: string;
  ext_password: string;
  ext_number: string;
}

export interface IPopAgentCampaign {
  VIETTEL: VIETTEL;
  VINAPHONE: VINAPHONE;
  MOBIFONE: MOBIFONE;
  OTHER: OTHER;
}

export interface IGuide {
  id: number;
  updated_at: string;
  created_at: string;
  type: string;
  name: string;
  image: any;
  description: string;
  feature: string;
  file: string;
  link: string;
}

export interface IPaymentPackage {
  id: string;
  index: number;
  slug: string;
  category: string;
  title: string;
  description: string;
  amount: number;
  extra_amount: number;
  is_extra_available: boolean;
  price: number;
  quantity: number;
  period: string;
  can_buy_extra_product: boolean;
  extra_product_detail: {
    quantity: number;
    printable_period: string;
    code: string;
    extra_amount: number;
    is_extra_available: boolean;
  }[];
}

export interface IPaymentPackageOrder {
  url: string;
  order_id: string;
  bank: string;
  bank_number: number;
  bank_account: string;
  transaction_body: string;
  transaction_amount: number;
}

export interface IPaymentSubscription {
  id: number;
  is_active: boolean;
  renew_at: string;
  end_at: string;
  auto_renew: boolean;
  current_used: number;
  quota: number;
  title: string;
  description: string;
  type: string;
  code: string;
  remain_quota: number;
  extra_amount: number;
  is_extra_available: boolean;
  is_extra_prepaid: boolean;
  period: string;
  is_expired: boolean;
  is_overuse: boolean;
  package: string;
  package_product: number;
}

export interface IWalletTransaction {
  id: number;
  status: string;
  type: string;
  amount: number;
  completed_at: string;
  rollback_at: any;
  pre_amount: number;
  post_amount: number;
  error_messages: string;
  is_revert: boolean;
  note: string;
  reason: string; // CALL, BUY_PHONE, MAINTAINING_FEE,
  updated_at: string;
  created_at: string;
}

export interface IOrderCreateResponse {
  id: string;
  package: {
    id: string;
    index: number;
    slug: string;
    category: string;
    title: string;
    description: string;
    amount: number;
    extra_amount: number;
    is_extra_available: boolean;
    price: number;
    quantity: number;
    period: string;
    can_buy_extra_product: boolean;
    extra_product_detail: {
      quantity: number;
      printable_period: string;
      code: string;
      extra_amount: number;
      is_extra_available: boolean;
    }[];
  };
  invoice: any;
  status: string;
  error_messages: string;
  type: string;
  is_tax: boolean;
  amount: number;
  promotion_amount: number;
  tax_amount: number;
  quantity: number;
  category: string;
  company_name: string;
  tax: string;
  email: string;
  location: string;
  qrcode: string;
  bank_name: string;
  bank_number: string;
  bank_user: string;
  bank_content: string;
  updated_at: string;
  created_at: string;
}

export enum IOrderCreateResponseEnum {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  ROLLBACK = 'ROLLBACK',
}

export interface ISubscriptionTransaction {
  id: number;
  updated_at: string;
  created_at: string;
  completed_at: string;
  rollback_at: any;
  status: string;
  type: string;
  amount: number;
  error_messages: string;
  note: number;
}

export type IOrderCartReport = {
  date: string;
  link: string;
  CALLBOT: {
    pre_money: {
      block: number;
      system_cash: number;
    };
    topup_money: {
      block: number;
      system_cash: number;
    };
    useup_money: {
      block: number;
      system_cash: number;
    };
    remain_money: {
      block: number;
      system_cash: number;
    };
    campaign: Array<{
      id: string;
      name: string;
      block: number;
      system_cash: number;
    }>;
    orders: Array<any>;
  };
}[];

export type propsData = {
  props: any;
};

export type InputRef = {
  input: HTMLInputElement | null;
  focus: () => void;
  blur: () => void;
  setSelectionRange: (start: number, end: number) => void;
  select: () => void;
};

export interface IAuthGroup {
  id: string;
  permissions: Permission[];
  profile_groups: any[];
  created_at: string;
  updated_at: string;
  is_default: boolean;
  name: string;
  description: string;
}

export interface Permission {
  id: number;
  name: string;
  codename: string;
}

export interface ICarrier {
  id: number;
  name: string;
  code: string;
}

export interface ISetting {
  record_local: boolean;
  record_inbound: boolean;
  record_outbound: boolean;
}

export interface IExtension {
  id: string;
  company: string;
  extension_number: number;
  password?: number;
  type: string;
  name?: string;
}

export interface IRecord {
  id: string;
  company: string;
  name: string;
  file: string;
}

export interface IHotline2 {
  id: any;
  phone_number: string;
  carrier: string;
  inbound_enable: boolean;
  outbound_enable: boolean;
  onnet_use_amount: number;
  mobile_use_amount: number;
  inbound: IInbound;
  outbound: IOutbound;
  currentFilter?: any;
}

export interface IInbound {
  id: string;
  company: string;
  inbound_enable: boolean;
  inbound_allow_record: boolean;
  destination: IExtension;
}

export interface IOutbound {
  id: string;
  company: string;
  outbound_enable: boolean;
  outbound_allow_record: boolean;
  allow_all_agent: boolean;
  allow_agents: any;
  allow_groups: any;
  no_limit: boolean;
  limit_carriers: ICarrier;
  backup_hotline: string;
  priority: number;
}

export interface IQueue {
  id: string;
  name: string;
  extension: IExtension;
  enable: boolean;
  greeting: IRecord;
  ringback: IRecord;
  strategy: string;
  max_wait: number;
  max_wait_no_agent: number;
  tiers: {
    id: string;
    queue: string;
    agent: {
      id: string;
      company: string;
      email: string;
    };
    state: string;
    position: number;
  };
  timeout_action: IExtension;
}

export interface IIvr {
  id: string;
  extension: IExtension;
  name: string;
  enable: boolean;
  greeting: IRecord;
  ringback: IRecord;
  invalid_sound: IRecord;
  max_timeout: number;
  max_fail: number;
  ivr0: IExtension;
  ivr1: IExtension;
  ivr2: IExtension;
  ivr3: IExtension;
  ivr4: IExtension;
  ivr5: IExtension;
  ivr6: IExtension;
  ivr7: IExtension;
  ivr8: IExtension;
  ivr9: IExtension;
  default: IExtension;
}

export interface ITimeCondition {
  id: string;
  extension: IExtension;
  name: string;
  enable: boolean;
  options: ITimeOption;
  destination: IExtension;
  alternate_destination: IExtension;
}

export interface ITimeOption {
  id: string;
  time_condition: string;
  enable: string;
  start: string;
  end: string;
}

export interface IUserInfo {
  user: {
    id: string;
    full_name: string;
    email: string;
    phone_number: string;
    apiKey: string;
    identification_card_front: any;
    identification_card_back: any;
    language: string;
  };
  company: {
    id: string;
    name: string;
    slug: string;
    partner: {
      id: string;
      ratio: number;
      is_default: boolean;
      name: string;
      logo: string;
      dark_logo: string;
      color: string;
      language: string;
      autocall_block: number;
      xfercall_block: number;
      scenariocall_block: number;
      price_voice_record: number;
      price_voice_basic: number;
      price_voice_advance: number;
      price_hotline_hub: number;
      price_hotline_own: number;
      price_social_start_crawl: number;
      price_social_call_success: number;
      bank_name: string;
      bank_number: string;
      bank_user: string;
      bank_content: string;
      bank_qr: string;
      bank_id: string;
      vietqr_template: string;
    };
    is_tax: boolean;
    company_name: string;
    tax: string;
    tax_email: string;
    location: string;
  };
}

export interface ITaxInfo {
  id: number;
  updated_at: string;
  created_at: string;
  is_default: boolean;
  type: string;
  email: string;
  person_name: string;
  person_tax: string;
  person_location: string;
  company_name: string;
  company_tax: string;
  company_location: string;
  wallet: string;
}

export interface IDetailWalletTransaction {
  id: string;
  reason: string;
  status: string;
  type: string;
  amount: number;
  pre_amount: number;
  post_amount: number;
  error_messages: string;
  hotline: {
    phone_number: string;
    carrier: string;
    type: string;
    setup_ammount: number;
    period_ammount: number;
    is_extension: boolean;
  };
  call: {
    from_number: any;
    to_number: string;
    record: string;
    duration: number;
    type: string;
    employee_email: string;
  };
}

export enum HotlineTypeEnum {
  AUTOCALL = 'AUTOCALL',
  AUTOCALL09 = 'AUTOCALL09',
  AUTOCALLOWN = 'AUTOCALLOWN',
  AUTOCALL_MOBILE = 'AUTOCALLRENT09',
  AUTOCALL_LANDLINE = 'AUTOCALLRENT02',
}

export const AUTOCALL_TYPES = [
  HotlineTypeEnum.AUTOCALL,
  HotlineTypeEnum.AUTOCALL09,
  HotlineTypeEnum.AUTOCALL_MOBILE,
  HotlineTypeEnum.AUTOCALL_LANDLINE,
  HotlineTypeEnum.AUTOCALLOWN,
];

export const SCENARIO_PACKAGE_TYPE = 'SCENARIO';
export const RECORD_PACKAGE_TYPE = 'RECORD';
export const COMBO_PACKAGE_TYPE = 'COMBO';
